/**
 * Flexslider Init (hero)
 */
jQuery(document).ready(function($){

    $('.slides__outer').flexslider({
        selector: ".slides > .slide",
        animation: "slide",
        controlNav: true,
        directionNav: false,
        animationLoop: true,
        slideshow: true,
        touch: true,
        start: function(){
            $('.slides').slideDown().scrollTop(0);
        },
    });
});
